import React, { useEffect, useState } from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'
import { isMobile } from 'react-device-detect'
import { Helmet } from 'react-helmet'
import PersonalizeLanding from '../PersonalizeLanding'
import Flag from 'react-flagpack'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'

import './style.css'

// import required modules
import { Pagination } from 'swiper'

import './style.css'
import { deviceDetect } from 'react-device-detect'
function Landing () {
  const [active, setActive] = useState(null)
  const [name, setName] = useState('Eaux Claires')
  const [step, setStep] = useState(0)
  // const [hasLoaded, setHasLoaded] = useState(false)
  const [locale, setLocale] = useState('US')
  const [selectedNum, setSelectedNum] = useState(1)
  const eaxNums = [1, 2, 4, 11, 124, 140, 249, 250]
  const lanNums = [1, 20, 44, 52, 97, 129, 198, 240, 250]
  useEffect(() => {}, [name])

  const setNumbers = e => {
    setSelectedNum(e.target.value)
    console.log(e.target.value)
  }

  const Nums = () => {
    return (
      <div style={{ margin: '5px' }}>
        <label for='nums'>
          {locale === 'US'
            ? 'Choose your bottle number: '
            : 'Choisissez votre numéro de bouteille: '}
        </label>
        <select
          name='nums'
          id='nums'
          onChange={e => setNumbers(e)}
          value={selectedNum}
        >
          {eaxNums.map(e => {
            return <option value={e}>{e}</option>
          })}
        </select>
      </div>
    )
  }
  return (
    <div>
      <center>
        <div
          className='flag'
          onClick={() => (locale === 'US' ? setLocale('FR') : setLocale('US'))}
        >
          <Flag code={locale} />
        </div>
      </center>

      {/* {step === 0 ? (
        <div className='landing'>
          <Helmet>
            <style>{'body { background-color: #191413; color: white; }'}</style>
          </Helmet>
          <h1 className='mh-title'>
            {locale === 'US'
              ? 'SELECT YOUR BOTTLE'
              : 'SÉLECTIONNEZ VOTRE BOUTEILLE'}
          </h1>
          <h4>{name}</h4>
          <Nums />

          <Swiper
            pagination={{
              clickable: true
            }}
            slidesPerView={1}
            spaceBetween={50}
            modules={[Pagination]}
            className='mySwiper'
            onSlideChange={e => {
              setActive(e.activeIndex)
              setName(e.activeIndex === 0 ? 'Eaux Claires' : "L'Antenne")
            }}
          >
            <SwiperSlide>
              <img
                src='https://solutions-engineering.s3.amazonaws.com/mh/H8_eauxClaires_00.jpg'
                className='swiper-image'
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src='https://solutions-engineering.s3.amazonaws.com/mh/H8_LAntenne_00.jpg'
                className='swiper-image'
              />
            </SwiperSlide>
          </Swiper>
          <div style={{ margin: '10px' }}>
            <a className='circle-title' href='#' onClick={() => setStep(1)}>
              <span className='badge' href='/'>
                {'>'}
              </span>
              <span style={{ marginLeft: '10px' }}>
                {locale === 'US' ? 'PROCEED' : 'PROCÉDÉ'}
              </span>
            </a>
          </div>
        </div>
      ) : ( */}
      <div>
        <PersonalizeLanding selected={name} locale={locale} num={selectedNum} />
      </div>
      {/* )} */}
    </div>
  )
}

export default Landing
