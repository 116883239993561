import React, { useState, useEffect } from 'react'
import './style.css'

function NumberSelect (props) {
  const [formVal, setFormVal] = useState('')
  const [availableNums, setAvailableNums] = useState(undefined)
  const [selected, setSelected] = useState()
  useEffect(() => {
    window.configurator.getDisplayAttributes().forEach(e => {
      console.log(e.name)
      let arr = []
      if (e.name === 'Engraving Number') {
        e.values.map(e => {
          arr.push(e.value)
        })
        console.log(arr)
        setSelected(arr[0])
        setAvailableNums(arr)
      }
    })

    if (window.configurator.getConfiguration()['Engraving Number'] != '') {
      setSelected(window.configurator.getConfiguration()['Engraving Number'])
    }
  }, [])

  const onChange = e => {
    console.log(props)
    setFormVal(e.target.value)
    window.configurator
      .setConfiguration({ Engraving: e.target.value })
      .then(() => {
        props.setPrice()
      })
  }
  const setNumbers = e => {
    setSelected(e.target.value)
    window.configurator.setConfiguration({ 'Engraving Number': e.target.value })
    console.log(e.target.value)
  }
  return (
    <div className='personalize center'>
      <h4 className='mh-title'>
        {props.locale === 'US' ? 'ENGRAVING NUMÉRO' : 'GRAVURE NUMÉRO'}
      </h4>
      <select
        name='nums'
        id='nums'
        className='mh-title'
        onChange={e => setNumbers(e)}
        value={selected}
        style={{width: '70px'}}
      >
        {availableNums
          ? availableNums.map(e => {
              return <option value={e}>{e}</option>
            })
          : null}
      </select><br/>

      {/* <input
        type='text'
        id='fname'
        name='fname'
        value={formVal}
        placeholder={props.locale === 'US' ? 'Name' : 'Nom'}
        className='mh-title'
        onChange={e => onChange(e)}
        maxLength='10'
        style={{ textTransform: 'uppercase' }}
      /> */}
      <br />
      <button onClick={() => props.exit()} className='log-out-btn'>
        Done
      </button>
    </div>
  )
}

export default NumberSelect
