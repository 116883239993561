import React, { useState, useEffect } from 'react'
import './style.css'

function Personalize (props) {
  const [formVal, setFormVal] = useState('')
  useEffect(() => {
    if (window.configurator.getConfiguration()['Engraving'] != '') {
      setFormVal(window.configurator.getConfiguration()['Engraving'])
    }
  }, [])

  const onChange = e => {
    console.log(props)
    setFormVal(e.target.value)
    window.configurator
      .setConfiguration({ Engraving: e.target.value })
      .then(() => {
        props.setPrice()
      })
  }
  return (
    <div className='personalize center'>
      <h4 className='mh-title'>
        {props.locale === 'US' ? 'ENGRAVING' : 'GRAVURE'}
      </h4>
      <input
        type='text'
        id='fname'
        name='fname'
        value={formVal}
        placeholder={props.locale === 'US' ? 'Name' : 'Nom'}
        className='mh-title'
        onChange={e => onChange(e)}
        maxLength='10'
        style={{ textTransform: 'uppercase' }}
      />
      <br /><br/>
     
      <button onClick={() => props.exit()} className='log-out-btn'>
        Done
      </button>
    </div>
  )
}

export default Personalize
