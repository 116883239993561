import './style.css'
import Player from '../Player'
import React, { useState, useEffect } from 'react'
import Personalize from '../Personalize'
import NumberSelect from '../NumberSelect'

function PersonalizeLanding (props) {
  const [personalize, setPersonalize] = useState(false)
  const [isZoom, setIsZoom] = useState(false)
  const [country, setCountry] = useState('USA')
  const [priceUSD, setPriceUSD] = useState('70000')
  const [priceEuro, setPriceEuro] = useState('65000')
  const [selected, setSelected] = useState('EAUX CLAIRES')
  // const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()
  const [step, setStep] = useState('main')

  const theme = {
    collapse: 'ReactCollapse--collapse',
    content: 'ReactCollapse--content'
  }
  useEffect(() => {
    // apply2DSpin({ attrName: 'Rotation', direction: 1 })(window.player)
  }, [])
  function setPrice () {
    if (window.configurator.getConfiguration()['Engraving'] != '') {
      setPriceUSD('75000')
      setPriceEuro('70000')
    } else {
      setPriceUSD(
        window.configurator.getPrice(
          'da1f8c6c-9c79-46d9-b4e4-84b4d41a8d43',
          'USD'
        )
      )
      setPriceEuro(
        window.configurator.getPrice(
          'da1f8c6c-9c79-46d9-b4e4-84b4d41a8d43',
          'EUR'
        )
      )
    }
  }

  function setBottle (e) {
    if (e === 'Eaux Claires') {
      setSelected('EAUX CLAIRES')
    } else {
      setSelected("L'ANTENNE")
    }
  }

  const SelectBottle = () => {
    return (
      <div className=''>
        <div
          className='btl-section'
          onClick={() => {
            window.configurator
              .setConfiguration({
                'H8 Bottle Style': 'Eaux Claires'
              })
              .then(e => setBottle(e['H8 Bottle Style']))
          }}
        >
          <img
            src='./eauxclaires.png'
            // style={{ verticalAlign: 'middle', height: '100px' }}
            className='btl-btn'
            style={{
              display: 'inline-block',
              height: '100px',
              verticalAlign: 'middle'
            }}
            onClick={() => {
              window.configurator
                .setConfiguration({
                  'H8 Bottle Style': 'Eaux Claires'
                })
                .then(e => setBottle(e['H8 Bottle Style']))
            }}
          />
          <a
            style={{ display: 'inline-block', cursor: 'pointer' }}
            onClick={() => {
              window.configurator
                .setConfiguration({
                  'H8 Bottle Style': 'Eaux Claires'
                })
                .then(e => setBottle(e['H8 Bottle Style']))
            }}
          >
            EAUX CLAIRES
          </a>
        </div>
        <div
          className='btl-section'
          onClick={() => {
            window.configurator
              .setConfiguration({
                'H8 Bottle Style': 'LAntenne'
              })
              .then(e => setBottle(e['H8 Bottle Style']))
          }}
        >
          <img
            src='./lantenne.png'
            style={{
              display: 'inline-block',
              height: '100px',
              verticalAlign: 'middle'
            }}
            className='btl-btn'
            onClick={() => {
              window.configurator
                .setConfiguration({
                  'H8 Bottle Style': 'LAntenne'
                })
                .then(e => setBottle(e['H8 Bottle Style']))
            }}
          />
          <a
            style={{ display: 'inline-block', cursor: 'pointer' }}
            onClick={() => {
              window.configurator
                .setConfiguration({
                  'H8 Bottle Style': 'LAntenne'
                })
                .then(e => setBottle(e['H8 Bottle Style']))
            }}
          >
            L'ANTENNE
          </a>
        </div>

        <br />
        <center>
          <button onClick={() => setStep('main')} className='log-out-btn'>
            Done
          </button>
        </center>
      </div>
    )
  }
  return (
    <div>
      <div className='logged-in'>
        <div className='left'>
          <div className='right-content'>
            <Player selected={props.selected} num={props.num} />
            <center>
              <div className='right-buttons'>
                <img
                  src='https://solutions-engineering.s3.amazonaws.com/mh/360.png'
                  style={{ height: '30px', width: '30px' }}
                  onClick={() => {
                    console.log('click')
                    let el = document.getElementsByClassName(
                      'canvas___BrMgq'
                    )[0]

                    el.style.pointerEvents = 'auto'
                    window.configurator.setConfiguration({ Camera: '360' })
                  }}
                  className='player-buttons'
                />
                <img
                  src='https://solutions-engineering.s3.amazonaws.com/mh/detail2.png'
                  style={{ height: '30px', width: '30px' }}
                  onClick={() => {
                    console.log('click')
                    window.configurator.setConfiguration({
                      Camera: 'detail1',
                      Rotation: 0
                    })
                    setIsZoom(true)
                    let el = document.getElementsByClassName(
                      'canvas___BrMgq'
                    )[0]
                    console.log(el)
                    el.style.pointerEvents = 'none'
                  }}
                  className='player-buttons'
                />
                <img
                  src='https://solutions-engineering.s3.amazonaws.com/mh/detail1.png'
                  onClick={() => {
                    console.log('click')
                    window.configurator.setConfiguration({
                      Camera: 'detail2',
                      Rotation: 0
                    })
                    setIsZoom(true)
                    let el = document.getElementsByClassName(
                      'canvas___BrMgq'
                    )[0]

                    el.style.pointerEvents = 'none'
                  }}
                  className='player-buttons'
                />
                {/* <p className="right-btn">x PERSONALISE</p>
              <p className="right-btn">x DETAILS</p>
              <p className="mh-title right-btn">x ADD</p> */}
              </div>
            </center>
          </div>
        </div>
        <div className='right'>
          <div className='left-content'>
            <h1 className='center'>
              {props.locale === 'US'
                ? 'MAKE YOUR OWN HENNESSY 8 -'
                : 'FAITES VOTRE PROPRE HENNESSY 8 -'}{' '}
              {selected.toUpperCase()}
            </h1>

            <h4 className='mh-title center'>
              {props.locale === 'US'
                ? 'PRIVILEGE OF PERSONALISATION'
                : 'PRIVILÈGE DE PERSONNALISATION'}
            </h4>
            <p className='center'>
              {step === 'main'
                ? props.locale === 'US'
                  ? 'Each of the 250 decanters can be personalized by engraving message on the last of the eight rings. Add your name to the exceptional.'
                  : "Chacune des 250 carafes peut être personnalisée en gravant un message sur le dernier des huit anneaux. Ajoutez votre nom à l'exceptionnel."
                : null}
            </p>
            {step === 'main' ? (
              <div>
                {/* Select bottle */}
                <div className='dropdown-container'>
                  <div
                    className='collapsible'
                    onClick={() => setStep('bottle')}
                  >
                    <div>
                      <img
                        src='./eauxclaires.png'
                        style={{
                          verticalAlign: 'middle',
                          height: '100px',
                          marginRight: '10px'
                        }}
                      />
                      <p
                        style={{
                          display: 'inline-block',
                          alignContent: 'center'
                        }}
                      >
                        {props.locale === 'US'
                          ? 'SELECT YOUR BOTTLE'
                          : 'SÉLECTIONNEZ VOTRE BOUTEILLE'}
                      </p>
                    </div>
                  </div>
                </div>

                {/* Select your number */}
                <div className='dropdown-container'>
                  <div
                    className='collapsible'
                    onClick={() => setStep('number')}
                  >
                    <div>
                      <img
                        src='./number.png'
                        style={{
                          verticalAlign: 'middle',
                          height: '67px',
                          marginRight: '10px'
                        }}
                      />
                      <p
                        style={{
                          display: 'inline-block',
                          alignContent: 'center'
                        }}
                      >
                        {props.locale === 'US'
                          ? 'CHOOSE YOUR NUMBER'
                          : 'CHOISISSEZ VOTRE NUMÉRO'}
                      </p>
                    </div>
                  </div>
                </div>

                {/* Stat Personalization */}
                <div className='dropdown-container'>
                  <div
                    className='collapsible'
                    onClick={() => setStep('personalize')}
                  >
                    <div>
                      <img
                        src='./pen.png'
                        style={{
                          verticalAlign: 'middle',
                          height: '80px',
                          marginRight: '10px'
                        }}
                      />
                      <p
                        style={{
                          display: 'inline-block',
                          alignContent: 'center'
                        }}
                      >
                        {props.locale === 'US'
                          ? 'PERSONALIZE'
                          : 'PERSONNALISER'}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {/* Bottle Selection */}
            {step === 'bottle' ? <SelectBottle /> : null}
            {step === 'personalize' ? (
              <Personalize
                exit={() => setStep('main')}
                locale={props.locale}
                setPrice={setPrice}
              />
            ) : null}
            {step === 'number' ? (
              <NumberSelect
                locale={props.locale}
                exit={() => setStep('main')}
              />
            ) : null}

            <h3 className='center'>
              {props.locale === 'US' ? '$' + priceUSD : '€' + priceEuro}
            </h3>
            {/* 
            <a
              className='circle-title'
              href='#'
              onClick={() =>
                personalize ? setPersonalize(false) : setPersonalize(true)
              }
            >
              <span className='badge' href='/'>
                {personalize ? '▲' : '▼'}
              </span>
              <span style={{ marginLeft: '10px' }}>
                {props.locale === 'US' ? 'PERSONALISE' : 'PERSONNALISER'}
              </span>
            </a> */}
          </div>
        </div>
      </div>
      <div className='logout-button'>
        <button
          onClick={() => {
            localStorage.removeItem('user')
            window.location.reload()
          }}
          className='log-out-btn'
        >
          Log out
        </button>
      </div>
    </div>
  )
}

export default PersonalizeLanding
