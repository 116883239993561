import React, { useState, useEffect } from 'react'
import './style.css'
import { apply2DSpin } from '../../Helpers/apply2DSpin'
function Player (props) {
  useEffect(() => {
    window
      .threekitPlayer({
        authToken: '64b758ea-4eda-4b3d-82c3-b249dd44e429',
        el: document.getElementById('player'),
        assetId: 'e6c4707d-598e-4676-b286-fad11b99707d',
        showLoadingThumbnail: false,
        showConfigurator: false,
        showAR: false
      })
      .then(async function (player) {
        window.player = player
        window.configurator = await player.getConfigurator()
        await window.configurator.prefetchAttributes(['Rotation'])

        // window.configurator.setConfiguration({
        //   'H8 Bottle Style': props.selected == "L'Antenne" ? "LAntenne" : "Eaux Claires"
        // })
        window.configurator.setConfiguration({
          'Engraving Number': `${props.num}`
        })

     
          apply2DSpin({ attrName: 'Rotation', direction: 1 })(player)
      })
  }, [])

  return (
    <div className='player-container'>
      <div id='player'></div>
    </div>
  )
}

export default Player
